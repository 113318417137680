import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import ICCTrophy2025 from "./pages/ICCTrophy2025";

// Function to Initialize Google Analytics & AdSense
const initializeGoogleServices = () => {
  if (!document.getElementById("google-analytics")) {
    const googleAnalyticsScript = document.createElement("script");
    googleAnalyticsScript.async = true;
    googleAnalyticsScript.src = "https://www.googletagmanager.com/gtag/js?id=G-3MZLYC5Y3L";
    googleAnalyticsScript.id = "google-analytics";
    document.head.appendChild(googleAnalyticsScript);

    const googleAnalyticsInlineScript = document.createElement("script");
    googleAnalyticsInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-3MZLYC5Y3L');
    `;
    document.head.appendChild(googleAnalyticsInlineScript);
  }

  if (!document.getElementById("google-adsense")) {
    const adSenseScript = document.createElement("script");
    adSenseScript.async = true;
    adSenseScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4334247791889882";
    adSenseScript.crossOrigin = "anonymous";
    adSenseScript.id = "google-adsense";
    document.head.appendChild(adSenseScript);
  }
};

// Function to Set Metadata
const setMeta = () => {
  document.title = "ICC Champions Trophy 2025 Live Streaming | Free Live Scores";
  if (!document.querySelector("meta[name='description']")) {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = "ICC Champions Trophy 2025 Live Streaming | Free Live Scores";
    document.head.appendChild(meta);
  }
};

// AI Chatbot Placeholder
const AIChatbot = () => (
  <div className="fixed bottom-4 right-4 p-4 bg-yellow-500 text-black rounded-lg shadow-lg">
    <p>Ask me about live matches!</p>
  </div>
);

// Home Page
const Home = () => {
  setMeta();
  initializeGoogleServices();
  const [searchTerm, setSearchTerm] = useState("");
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  // ✅ Fetch channels from API
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await fetch("https://live-streaming-site.onrender.com/api/channels");
        const data = await response.json();
        setChannels(data);
      } catch (err) {
        console.error("Failed to fetch channels:", err);
      } finally {
        setLoading(false); // Stop loading after fetching channels  
      }
    };

    fetchChannels();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center p-6">
      <h1 className="text-4xl font-bold mb-6 text-yellow-400 drop-shadow-md">ICC Champions Trophy 2025 | Free Live Streaming | Free Live Scores</h1>
      
      {/* 🔗 Add the ICC Champions Trophy 2025 Button */}
      <Link to="/icc-champions-trophy-2025" className="mt-4 text-yellow-400 underline hover:text-red-500 transition">
        📺 Watch ICC Champions Trophy 2025
      </Link>

      {/* Show Loading Spinner */}
      {loading && <p className="text-yellow-500 animate-pulse">Loading channels...</p>}

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search for a channel..."
        className="p-3 mb-6 border border-yellow-400 rounded-lg text-black w-3/4 md:w-1/2 text-center"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Channel Grid with Proper Spacing */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-6xl text-center">
        {channels
          .filter(channel => channel.title.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((channel) => (
            <Link key={channel.id} to={`/watch/${channel.id}`} className="border p-4 rounded-lg shadow-lg hover:shadow-xl transition w-full bg-gray-900 text-white">
              <h3 className="text-lg font-semibold text-white">{channel.title}</h3>
            </Link>
          ))}
          
      </div>

      <AIChatbot />
    </div>
  );
};

// Watch Page
const Watch = () => {
  const { id } = useParams();
  const [streamUrl, setStreamUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchStreamUrl = async () => {
      try {
        console.log(`Fetching stream: https://live-streaming-site.onrender.com/api/stream/${id}`); // Debugging
        const response = await fetch(`https://live-streaming-site.onrender.com/api/stream/${id}`);
        const data = await response.json();

        if (data.url) {
          setStreamUrl(data.url);
        } else {
          setError("Stream not available.");
        }
      } catch (err) {
        setError("Failed to fetch stream. Please try again later.");
      } finally {
        setLoading(false); // ✅ Now `setLoading` is used to stop loading spinner
      }
    };

    fetchStreamUrl();
  }, [id]);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center p-6">
      <h1 className="text-3xl font-bold mb-4 text-yellow-400 drop-shadow-md">
        {streamUrl ? `Now Playing: ${id.replace(/-/g, " ")}` : "Channel Not Found"}
      </h1>

      {loading ? ( // ✅ Now using the loading state
        <p className="text-yellow-500 animate-pulse">Loading stream...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <p className="mb-4 text-red-500 font-semibold animate-pulse">Tell your friends about me</p>
          <div className="border border-yellow-400 rounded-lg overflow-hidden shadow-lg">
            <ReactPlayer url={streamUrl} controls width="100%" height="500px" playing />
          </div>
        </>
      )}
      <Link to="/" className="mt-4 text-yellow-400 underline hover:text-red-500 transition">
        Back to Home
      </Link>
    </div>
  );
};

// Main App
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/watch/:id" element={<Watch />} />
  <     Route path="/icc-champions-trophy-2025" element={<ICCTrophy2025 />} />
      </Routes>
    </Router>
  );
};

export default App;

document.addEventListener("contextmenu", function (e) {
  e.preventDefault(); // Disable right-click
});

document.addEventListener("keydown", function (e) {
  if (e.ctrlKey && (e.key === "u" || e.key === "U")) {
    alert("Viewing source code is disabled!"); 
    e.preventDefault();
  }
  if (e.ctrlKey && e.shiftKey && e.key === "I") {
    alert("Inspect Element is disabled!");
    e.preventDefault();
  }
  if (e.ctrlKey && e.shiftKey && e.key === "C") {
    alert("Inspect Element is disabled!");
    e.preventDefault();
  }
  if (e.ctrlKey && e.shiftKey && e.key === "J") {
    alert("Console is disabled!");
    e.preventDefault();
  }
  if (e.ctrlKey && e.key === "S") {
    alert("Saving is disabled!");
    e.preventDefault();
  }
  if (e.ctrlKey && e.key === "A") {
    alert("Select all is disabled!");
    e.preventDefault();
  }
  if (e.key === "F12") {
    alert("Developer tools are disabled!");
    e.preventDefault();
  }
});
