import React, { useState, useEffect } from "react";

const ICCTrophy2025 = () => {
  const API_KEY = "e9c856e7-3fe9-4848-b2cb-1296760de3e3"; // Replaced with the actual API key

  // ✅ Debugging: Check if API Key is loaded
  console.log("API Key:", API_KEY); // Add this line
  
  // States for storing API data (Ensure they default to empty arrays)
  const [matches, setMatches] = useState([]);
  const [liveScores, setLiveScores] = useState([]);
  const [series, setSeries] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  // 🏏 Fetch Match Schedule
  useEffect(() => {
    fetch(`https://api.cricapi.com/v1/matches?apikey=${API_KEY}&offset=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          // ✅ Filter ONLY matches that belong to ICC Champions Trophy 2025
          const filteredMatches = data.data.filter(match => 
            match.series_id === "ICC_Champions_Trophy_2025" // Replace with actual ID
          );
          setMatches(filteredMatches);
        } else {
          setMatches([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("❌ Error fetching matches:", err);
        setMatches([]);
        setLoading(false);
      });
  }, [API_KEY]);  

  // 🔴 Fetch Live Scores
  useEffect(() => {
    fetch(`https://api.cricapi.com/v1/cricScore?apikey=${API_KEY}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          // ✅ Filter ONLY ICC Champions Trophy 2025 matches
          const filteredScores = data.data.filter(match => 
            match.series === "ICC Champions Trophy 2025"
          );
          setLiveScores(filteredScores);
        } else {
          setLiveScores([]);
        }
      })
      .catch((err) => {
        console.error("❌ Error fetching live scores:", err);
        setLiveScores([]);
      });
  }, [API_KEY]);  

  // 📆 Fetch Series List
  useEffect(() => {
    fetch(`https://api.cricapi.com/v1/series?apikey=${API_KEY}&offset=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          // ✅ Only show ICC Champions Trophy 2025
          const filteredSeries = data.data.filter(series => 
            series.name.includes("ICC Champions Trophy")
          );
          setSeries(filteredSeries);
        } else {
          setSeries([]);
        }
      })
      .catch((err) => {
        console.error("❌ Error fetching series:", err);
        setSeries([]);
      });
  }, [API_KEY]);  

  // 👤 Fetch Player List
  useEffect(() => {
    fetch(`https://api.cricapi.com/v1/players?apikey=${API_KEY}&offset=0`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          // ✅ Only show players from ICC Champions Trophy Teams
          const iccTeams = ["India", "Pakistan", "Australia", "England", "South Africa", "Sri Lanka", "New Zealand", "West Indies"];
          const filteredPlayers = data.data.filter(player => 
            iccTeams.includes(player.country)
          );
          setPlayers(filteredPlayers);
        } else {
          setPlayers([]);
        }
      })
      .catch((err) => {
        console.error("❌ Error fetching players:", err);
        setPlayers([]);
      });
  }, [API_KEY]);  

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center p-6">
      <h1 className="text-4xl font-bold text-yellow-400 drop-shadow-md">🏏 ICC Champions Trophy 2025 Live Streaming</h1>

      <p className="text-lg mt-4">
        Watch **ICC Champions Trophy 2025 live streams for free!** Stay updated with **match schedules, live scores, and all ICC match details.**
      </p>

      {/* 🏏 Live Scores Section */}
      <h2 className="text-2xl font-semibold mt-6">🔴 Live Scores</h2>
      {liveScores.length > 0 ? (
        <ul className="mt-4">
          {liveScores.map((match) => (
            <li key={match.id} className="border p-4 rounded-lg my-2 bg-gray-800">
              <p className="text-lg font-bold">{match.name}</p>
              <p className="text-yellow-400">{match.status}</p>
              {match.score && match.score.length > 0 && (
                <p className="text-yellow-400">
                  🏏 {match.score[0].inning}: {match.score[0].r}/{match.score[0].w} in {match.score[0].o} overs
                </p>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">No live matches currently.</p>
      )}

      {/* 📆 Match Schedule Section */}
      <h2 className="text-2xl font-semibold mt-6">📆 ICC Champions Trophy 2025 Schedule</h2>
      {loading ? <p className="text-gray-400">Loading matches...</p> : (
        <ul className="mt-4">
          {matches.length > 0 ? (
            matches.slice(0, 5).map((match) => (
              <li key={match.id} className="border p-4 rounded-lg my-2 bg-gray-800">
                <p className="text-lg font-bold">{match.name}</p>
                <p className="text-gray-300">{match.date} | {match.venue}</p>
              </li>
            ))
          ) : (
            <p className="text-gray-400">No match data available.</p>
          )}
        </ul>
      )}

      {/* 🏆 Series List Section */}
      <h2 className="text-2xl font-semibold mt-6">🏆 ICC Champions Trophy 2025 Series</h2>
      {series.length > 0 ? (
        <ul className="mt-4">
          {series.map((s) => (
            <li key={s.id} className="border p-4 rounded-lg my-2 bg-gray-800">
              <p className="text-lg font-bold">{s.name}</p>
              <p className="text-gray-300">Start: {s.startDate} | End: {s.endDate}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">No series data available.</p>
      )}

      {/* 👤 Players Section */}
      <h2 className="text-2xl font-semibold mt-6">👤 Star Players</h2>
      {players.length > 0 ? (
        <ul className="mt-4">
          {players.slice(0, 10).map((player) => (
            <li key={player.id} className="border p-4 rounded-lg my-2 bg-gray-800">
              <p className="text-lg font-bold">{player.name}</p>
              <p className="text-gray-300">Country: {player.country}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">No player data available.</p>
      )}

      {/* 🔴 Live Streaming Button */}
      <h2 className="text-2xl font-semibold mt-6">🔴 Watch Live</h2>
      <p className="mt-4">
        📺 Watch live matches: <a href="https://lievscores.com" className="text-yellow-400">Click Here</a>
      </p>
    </div>
  );
};

export default ICCTrophy2025;
